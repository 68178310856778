import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class TopnavComponent implements OnInit {

	global:any;
	notify:boolean;
	user:any;
	url:any;
	fsize:any = '14px';
	headerImage:any;
	constructor(public auth:AuthenticationService, private router: Router){
		this.url = this.router.url;
		this.auth.getAPI('notice/').subscribe(result => {
			this.global = result['results'];
		});

		this.auth.getAPI('headerimage/').subscribe(result => {
			this.headerImage = result['results'];
		});
	}

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('profile'));
		var fnt = 0;
		if(this.user.reports){ fnt += 1; }
		if(this.user.message){ fnt += 1; }
		if(this.user.livechat){ fnt += 1; }
		if(this.user.nqs){ fnt += 1; }
		if(this.user.tips){ fnt += 1; }
		if(this.user.score){ fnt += 1; }
		if(this.user.rating){ fnt += 1; }
		if(this.user.pod_finder){ fnt += 1; }
		if(this.user.order_finder){ fnt += 1; }
		if(this.user.cadrk_view || this.user.cadrk_edit || this.user.cadrk_edit_only || this.user.cadrk_view_only || this.user.cadrk_delete){ fnt += 2; }
		this.fsize = (14-(fnt/2))+'px';
		this.auth.getAPI('user/'+this.user['id']+'/').subscribe(result => {
			this.user = result;
			this.notify = this.user['notification'];
		});
	}

	onToggle(e){
		this.auth.patchAPI('user/'+this.user['id']+'/', {'notification':this.notify}).subscribe(result => {
			this.user = result;
			this.notify = this.user['notification'];
		});
	}

	logout(){
		localStorage.clear();
		window.location.href = this.auth.baseUrl+'/login';
		// window.location.href = this.auth.baseUrl+'/logout';
	}

}
